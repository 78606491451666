<template>
  <div class="tab">
    <div class="tab-first">
      <div class="tab-first-left">
        <div class="tab-first-1">
          <div class="tab-first-1-title">友情链接</div>
          <div
            class="tab-first-1-list"
            v-for="(item, index) in 5"
            :key="index"
            @click="indexClick"
          >
            {{
              index == 0
                ? "零壹财经"
                : index == 1
                ? "36氪"
                : index == 2
                ? "雷锋网"
                : index == 3
                ? "猎云网"
                : "亿欧网"
            }}
          </div>
        </div>

        <div class="tab-first-1" style="margin-left: 124px">
          <div class="tab-first-1-title">产品服务</div>
          <div
            class="tab-first-1-list"
            v-for="(item, index) in 6"
            :key="index"
            @click="service(index)"
          >
            {{
              index == 0
                ? "标准Al产品"
                : index == 1
                ? "定制风控服务"
                : index == 2
                ? "数字化转型赋能"
                : index == 3
                ? "小微综合解决方案"
                : index == 4
                ? "EA优势"
                : "EA产品介绍"
            }}
          </div>
        </div>

        <div class="tab-first-1" style="margin-left: 124px">
          <div class="tab-first-1-title">关于汇智启航</div>
          <div
            class="tab-first-1-list"
            v-for="(item, index) in 4"
            :key="index"
            @click="regard"
          >
            {{
              index == 0
                ? "公司简介"
                : index == 1
                ? "企业文化"
                : index == 2
                ? "创始团队"
                : "资质荣誉"
            }}
          </div>
        </div>
      </div>

      <div class="tab-first-right">
        <img class="tab-first-right-logo" src="../../assets/logo2.png" />
        <div class="tab-first-right-qrcore">
          <img src="../../assets/qrcode.png" alt="" />
        </div>
      </div>
    </div>

    <div class="tab-second">
      Copyright©2019汇智启航科技.All rights reserved.ICP许可证号粤ICP备2022131520号-1
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 友情链接
    indexClick(index) {
      if (index == 0) {
        window.open("https://www.01caijing.com  ");
      } else if (index == 1) {
        window.open("https://36kr.com");
      } else if (index == 2) {
        window.open("https://www.leiphone.com");
      } else if (index == 3) {
        window.open("https://lieyunwang.cn/");
      } else {
        window.open("https://www.iyiou.com");
      }
    },
    // 关于汇智启航
    regard() {
      this.$router.push({
        path: "/enterprise",
      });

      this.$emit("routerPath");
    },
    // 服务
    service(index) {
      console.log("点击服务", index);
      if (index <= 4) {
        this.$router.push({
          path: "/",
        });

        localStorage.setItem("home", 1);

        this.$router.go(0);
      } else {
        this.$router.push({
          path: "/product",
        });
      }
    },
  },
};
</script>


<style lang="less" scoped>
.tab {
  width: 100%;
  .tab-first {
    height: 361px;
    width: 100%;
    background: linear-gradient(315deg, #476eae 0%, #415286 100%);
    padding: 65px 310px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .tab-first-left {
      display: flex;
      .tab-first-1 {
        text-align: left;
        .tab-first-1-title {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 12px;
        }
        .tab-first-1-list {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 30px;
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }

    .tab-first-right {
      .tab-first-right-logo {
        // width: 190px;
        height: 48px;
      }
      .tab-first-right-qrcore {
        margin: 0 auto;
        margin-top: 30px;
        width: 130px;
        height: 130px;
        border: 1px solid;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .tab-second {
    width: 100%;
    height: 60px;
    background: linear-gradient(315deg, #476eae 0%, #415286 99%, #29324d 100%);
    text-align: center;
    line-height: 60px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>