<template>
  <div class="deal">
    <div class="deal-title">隐私权政策</div>
    <div class="deal-text">最新版本生效日期：2024年7月12日</div>

    <div class="deal-text1">
      欢迎您使用我们的产品和服务！我们深知个人信息对您的重要性，您的信任对我们非常重要，我们将根据法律法规要求并参照行业最佳实践为您的个人信息安全提供充分保障。为此，我们制定本《隐私权政策》（以下称“本政策”/“本隐私权政策”）以帮助您充分了解：本政策适用于深圳市德文华凯互联网电子商务责任有限公司（以下简称“我们”/“汇智启航科技”）提供的产品和服务及相关功能。
    </div>

    <div class="deal-text1">
      在您开始使用我们的产品和服务前，请您务必仔细阅读和理解本政策，特别应重点阅读我们以粗体标识的条款，确保您充分理解和同意后再开始使用。
    </div>
    <div class="deal-text1">本隐私权政策主要向您说明：</div>
    <div class="deal-text2">【我们如何收集和使用您的个人信息】</div>
    <div class="deal-text1" style="font-weight: bold">
      汇智启航科技作为金融衍生品行业一站式服务提供商，为您提供便捷的金融服务，为了实现服务的提供，我们主要会基于如下需要收集、使用、共享、存储您的个人信息：
    </div>
    <div class="deal-text1">
      1、用户注册/登录：在您访问我们的客户端时，虽然您可以匿名浏览商品信息，但您需要注册成为汇智启航科技网站用户后才能下单交易及享受其他用户服务。在注册过程中会收集手机号码等信息
    </div>
    <div class="deal-text1">
      2、商品/服务的展示：为提升您的网购效率，我们会利用我们的技术向您展示更契合您需求的商品/服务。
    </div>
    <div class="deal-text1">
      3、下单及交易履约：您需要提供收货人、收货地址、联系电话信息以便于我们帮助您顺利完成交易并保障您的交易安全。
    </div>
    <div class="deal-text1">
      4、客服及争议处理：在为您提供客服或争议纠纷处理服务时，我们需要收集和处理您的必要信息，包括麦克风权限、相机权限、以及您的联系方式、账号信息以及其他相关信息。来用于验证您的身份、向您回复及回访，提高我们服务人员的服务质量。
    </div>
    <div class="deal-text1">
      5、评论、分享等信息发布：您可选择使用评论、分享等其他信息发布功能，向其他用户分享您的购物体验。
    </div>
    <div class="deal-text1">
      6、安全保障：为保障您的账号及交易安全，我们需要收集和处理您的设备信息、我们也会将其用于预防各类非法使用或侵害其他主体合法权益的行为。
    </div>
    <div class="deal-text1">
      7、风控服务：您在汇智启航科技参加各类拉新注册活动，或者积分抽奖活动，为了保证用户参与活动的公平性，防止用户开启手机分屏功能或者采用第三方工具获取不正当利益，公司在特定的活动期间会收集用户设备上的应用安装列表。获取范围仅限于活动开启前一周时间内设备安装应用的情况。
    </div>
    <div class="deal-text1">
      8、其他服务：我们会收集您的位置信息、IMEI、手机号码等信息来用于订单交易管理更精确获取您的交易信息和基于地理位置服务、安全保障、推荐信息以及扫码用于获取id信息参与产品活动以致于为您带来更好的体验。
    </div>
    <div class="deal-text2">【您如何管理自己的信息】</div>
    <div class="deal-text1">
      您可以通过多种方式管理您的个人信息，例如自行删除购物车里的商品，更新您的个人信息，以及注销您的账号。
    </div>
    <div class="deal-text2">【未成年人个人信息保护】</div>
    <div class="deal-text1">
      我们一直非常重视并致力于对未成年人个人信息的保护。如果您是未成年人的，我们建议您和您的父母或其他监护人一起阅读本政策；如果您是8周岁以下儿童的，在您使用我们的服务前，您和您的父母或其他监护人应仔细阅读我们制定的《隐私权政策》，在确保已取得父母或其他监护人对《隐私权政策》的同意后，您才可在父母或其他监护人的指导下使用我们的服务，确保您在使用我们的服务和进行交易时的安全。
    </div>
    <div class="deal-text2">【本政策适用范围】</div>
    <div class="deal-text1">
      本政策适用于汇智启航科技以网站向您提供的各项产品和服务。
    </div>
    <div class="deal-text1">
      除本政策说明的相关信息收集使用活动外，本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您另行说明的隐私权政策。例如，当您加入和成为第三方的用户时，则该第三方对用户信息的使用适用其专门向您说明的规则。
    </div>
    <div class="deal-text2">【联系我们】</div>
    <div class="deal-text1">
      如您对本政策有任何疑问、意见或建议，或者您在管理您的个人信息时遇到任何问题，您可通过我们提供的渠道与我们联系：
    </div>
    <div class="deal-text1">
      您的个人信息相关咨询、投诉问题、发现个人信息可能被泄露，您可以通过乡米网客服电话与我们联系。
    </div>
    <div class="deal-text2">提示条款</div>
    <div class="deal-text1">
      您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，汇智启航科技（或简称“我们”）制定本《隐私权政策》（下称“本政策/本隐私权政策”），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
    </div>
    <div class="deal-text1">
      在使用乡米网各项产品或服务前，请您务必仔细阅读并透彻理解本政策特别是以粗体标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表达向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策“如何联系我们”章节所列的渠道与我们联系。
    </div>
    <div class="deal-text1">本隐私权政策将帮助您了解以下内容：</div>
    <div class="deal-text2">一、定义及适用范围</div>
    <div class="deal-text2">二、我们如何收集和使用您的信息</div>
    <div class="deal-text2">三、我们如何共享、转让、公开披露您的信息</div>
    <div class="deal-text2">四、我们如何保护您的信息</div>
    <div class="deal-text2">五、我们如何存储您的信息</div>
    <div class="deal-text2">六、您如何管理您的信息</div>
    <div class="deal-text2">七、我们如何处理未成年人的信息</div>
    <div class="deal-text2">八、本隐私权政策如何更新</div>
    <div class="deal-text2">九、如何联系我们</div>
    <div class="deal-text2">一、定义及适用范围</div>

    <div class="deal-text1">（一）定义</div>
    <div class="deal-text1">汇智启航科技网站：指汇智启航科技提供金融衍生产品的网站</div>
    <div class="deal-text1">
      个人信息：指以电子或其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
    </div>
    <div class="deal-text1" style="color: red; text-decoration: underline">
      个人敏感信息：指包括身份证号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、8周岁以下（含）儿童个人信息等的个人信息（我们将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识）。
    </div>
    <div class="deal-text1">
      个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
    </div>
    <div class="deal-text1">儿童：指不满8周岁的未成年人。</div>
    <div class="deal-text1">（二）适用范围</div>
    <div class="deal-text1">
      本政策适用于汇智启航科技以网站向您提供的各项产品和服务。除本政策说明的相关信息收集使用活动外，本政策不适用于其他第三方向您提供的服务，第三方向您提供的服务适用其向您另行说明的隐私权政策，也不适用于万战中已另行独立设置隐私权政策的产品和服务。例如，当您加入和成为第三方的用户时，则该第三方对用户信息的使用适用其专门向您说明的规则。
    </div>
    <div class="deal-text2">
      二、我们如何收集、使用您的信息以及可以如何保管您的信息
    </div>
    <div class="deal-text1">
      在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用您的个人信息包括如下两种：
    </div>
    <div class="deal-text1">
      1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您决绝提供相应的信息，您将无法正常使用我们的产品及/或服务；
    </div>
    <div class="deal-text1">
      2、为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们
      产品及/或服务的基本功能。
    </div>
    <div class="deal-text1">您理解并同意：</div>
    <div class="deal-text1">
      1、我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；
    </div>
    <div class="deal-text1">
      2、为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过本政策文末公式的联系方式与我们联系，我们会尽快为您作出解答。
    </div>
    <div class="deal-text1">我们会为您提供的各项具体功能场景包括：</div>
    <div class="deal-text1">（一）帮助您成为我们的用户及账号管理</div>
    <div class="deal-text1">1、基础用户服务</div>
    <div class="deal-text1">
      我们通过汇智启航科技账号为您提供基础用户服务。为注册成为用户并使用我们的用户服务，您需要提供您的手机号码、拟使用的用户名和密码用于创建账号。如果您仅需使用浏览、搜索服务，您不需要注册成为我们的用户及提供上述信息。
    </div>
    <div class="deal-text1">
      为了确保我们是在为您本人提供服务，我们可能会根据您提供的上述信息效验您的身份。如存在依法需确定您用户身份的场景（包括依法为您扣缴税费、行政执法或司法诉讼中相关主体认定等）时，我们可能会根据您提供的上述信息效验您的用户身份，确保我们实在为您本人提供服务和用于上述目的。
    </div>
    <div class="deal-text1">
      我们可能会根据您的用户账号使用情况及平台设置的用户类别计算规则确定您当前的用户类别，并为您提供相应用户类别所对应的基本权益。
    </div>
    <div class="deal-text1">
      2、账号信息展示：如果您已拥有汇智启航科技网账号，我们可能会在网站平台服务中显示您的上述个人信息，包括通过乡米网账号集中展示您的个人资料、优惠权益、交易订单等。我们会尊重您对汇智启航科技网站平台服务所做的选择。
    </div>
    <div class="deal-text2">三、我们是如何共享、转让、公开披露您的信息</div>
    <div class="deal-text1">（一）共享</div>
    <div class="deal-text1">
      我们不会与汇智启航科技网站服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
    </div>
    <div class="deal-text1">
      1、在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
    </div>
    <div class="deal-text1">
      2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
    </div>
    <div class="deal-text1">
      3、为您提供您需要的产品或服务必要的情况下共享：您通过乡米网购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易、配送及售后服务需求。
    </div>
    <div class="deal-text1">（二）转让</div>
    <div class="deal-text1">
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </div>
    <div class="deal-text1">1、在获取明确同意的情况下转让；</div>
    <div class="deal-text1">
      2、在汇智启航科技服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
    </div>
    <div class="deal-text1">
      （三）共享、转让、公开披露个人信息时事先征得授权同意的例外
    </div>
    <div class="deal-text1">
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </div>
    <div class="deal-text1">1、与我们履行法律法规规定的义务相关的；</div>
    <div class="deal-text1">2、与国家安全、国防安全有关的；</div>
    <div class="deal-text1">3、与公共安全、公共卫生、重大公共利益有关的；</div>
    <div class="deal-text1">
      4、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；
    </div>
    <div class="deal-text1">
      5、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </div>
    <div class="deal-text1">6、您自行向社会公众公开的个人信息；</div>
    <div class="deal-text1">
      7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </div>
    <div class="deal-text1">
      请知悉，根据适用的法律，如我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。
    </div>
    <div class="deal-text2">四、我们如何保护您的信息</div>
    <div class="deal-text1">（一）我们采取的数据保护技术措施</div>
    <div class="deal-text1">
      我们已采取符合业界标准、合理可行的安全措施保护您的信息。防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
    </div>
    <div class="deal-text1">（二）我们采取的数据保护管理组织措施</div>
    <div class="deal-text1">
      我们已设置了个人信息保护专职人员，并定时举办安全和隐私保护培训课程，加强员工对于个人信息重要性的认识。
    </div>
    <div class="deal-text1">（三）个人信息安全事件的响应</div>
    <div class="deal-text1">
      如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理救济措施，以尽可能降低对您个人信息和其他权益的影响。在不幸发生个人信息安全事件后，我们还将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议。对您的补救措施等。事件相关情况我们将以推送通知、短信、电话等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </div>
    <div class="deal-text1">（四）您也应保护您的账号安全</div>
    <div class="deal-text1">
      我们强烈建议您在使用汇智启航科技网站服务时，请使用复杂密码，协助我们保护您的账号安全。
    </div>
    <div class="deal-text1">
      在使用汇智启航科技网站服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。如您发现自己的个人信息尤其是账号或密码发生泄露，请您立即联络客服电话以便我们根据您的申请采取相应措施。
    </div>
    <div class="deal-text1">
      请注意，您在使用我们服务时自愿公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
    </div>
    <div class="deal-text2">五、我们如何存储您的信息</div>
    <div class="deal-text1">（一）存储地点</div>
    <div class="deal-text1">
      我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内。
    </div>
    <div class="deal-text1">（二）存储期限</div>
    <div class="deal-text1">
      我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务服务信息、交易信息保存时间自交易完成之日起不少于三年。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：
    </div>
    <div class="deal-text1">
      1、完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；
    </div>
    <div class="deal-text1">2、保证我们为您提供服务的安全和质量；</div>
    <div class="deal-text1">3、您是否同意更长的留存期间；</div>
    <div class="deal-text1">4、是否存在保留期限的其他特别预定。</div>
    <div class="deal-text2">六、您如何管理您的信息</div>
    <div class="deal-text1">
      您除了可以按照本政策相应章节中的操作路径在我们的客户端上自行操作以外，您还可以通过以下方式访问及管理您的信息：
    </div>
    <div class="deal-text1">（一）查询、更正和补充您的信息</div>
    <div class="deal-text1">
      您有权查询、更正或补充您的信息、您可以通过以下方式自行进行：
    </div>
    <div class="deal-text1">
      1、如果您希望访问或编辑您的账号中个人基本信息（如账号密码、昵称、性别、地址信息、感兴趣的类别等），您可以登陆客户端通过”我的设置”执行此类操作。
    </div>
    <div class="deal-text1">
      2、如果您无妨通过上述路径访问、更正个人信息，您可以通过汇智启航科技网站客服电话与我们联系寻求帮助，协助您查询、更正或补充您的信息。
    </div>
    <div class="deal-text1">（二）删除您的信息</div>
    <div class="deal-text1">
      你可以通过“（一）查询、更正和补充您的信息”中列明的方式删除您的部分信息。
    </div>
    <div class="deal-text1">
      在以下情形中，您可以通汇智启航科技网站客服电话与我们联系向我们提出删除个人信息的请求：
    </div>
    <div class="deal-text1">1、如果我们处理个人信息的行为违反法律法规；</div>
    <div class="deal-text1">
      2、如果我们收集、使用您的个人信息，却未征得您的明确同意；
    </div>
    <div class="deal-text1">
      3、如果我们处理个人信息的行为严重违反了与您的约定；
    </div>
    <div class="deal-text1">4、如果您主动注销了账号；</div>
    <div class="deal-text1">
      当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息，直到备份可以清除或实现匿名化。
    </div>
    <div class="deal-text1">（三）注销您的账号</div>
    <div class="deal-text1">您可以通过以下方式申请注销您的账号：</div>
    <div class="deal-text1">
      登录汇智启航科技网站并选择联系客服电话，“我的-客服”寻求客服帮助，协助您申请注销您的账号。
    </div>
    <div class="deal-text1">
      在您主动注销账号之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息。或使其匿名化处理。
    </div>
    <div class="deal-text1">
      在您主动注销账号之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息。或使其匿名化处理。
    </div>
    <div class="deal-text1">
      为保障您的账号安全，您向我们提出上述请求时，我们可能会先要求您验证自己的身份（如要求您提供书面请求或其他方式），然后再处理您的请求。
    </div>
    <div class="deal-text1">
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
    </div>
    <div class="deal-text1">
      在以上情形中，按照法律法规要求，我们将无法响应您的请求：
    </div>

    <div class="deal-text1">1、与我们履行法律法规规定的义务相关的；</div>
    <div class="deal-text1">2、与国家安全、国防安全直接相关的；</div>
    <div class="deal-text1">
      3、与公共安全、公共卫生、重大公共利益直接相关的；
    </div>
    <div class="deal-text1">
      4、与刑事犯罪侦查、起诉、审判和执行判决等直接相关的；
    </div>
    <div class="deal-text1">
      5、有充分证据表明个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
    </div>

    <div class="deal-text1">
      6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
    </div>
    <div class="deal-text1">
      7、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
    </div>
    <div class="deal-text1">8、涉及商业秘密的</div>
    <div class="deal-text2">七、关于第三方SDK管理</div>
    <div class="deal-text1">
      为了给您提供更专业、安全的核心业务功能和附加业务功能，我们的产品或服务中会接入第三方SDK产品。在您同意隐私政策之前，第三方SDK不会收集您的信息。
    </div>

    <div class="deal-text1">1、支付</div>
    <div class="deal-text1">
      如您在汇智启航科技网站需要交易支付使用支付宝、微信支付提供的第三方支付功能，支付宝（支付宝网络有限公司）SDK可能会收集您的设备型号、MAC地址、无线MAC地址、无线网络、传感器信息、IMEI、Android
      ID信息，隐私连接 说明 https://opendocs.alipay.com/open/54/。
    </div>
    <div class="deal-text1">
      微信支付（腾讯科技（深圳）有限公司）SDK会收集您的设备信息（可能包括IMEI、IMSI、设备MAC地址、Android
      Id、设备型号、网络类型、无线网络列表、无线网络MAC地址、无线网络名称、传感器信息、SIM序列号信息）、位置信息、手机号，隐私连接说明
      https://developers.weixin.qq.com/doc/oplatform/Mobile_App/WeChat_Pay/Android.html
    </div>
    <div class="deal-text1">2、售后服务</div>
    <div class="deal-text1">
      1）如您在汇智启航科技如要与客服进行联系，需要使用北京易掌云峰科技有限公司所提供的环信即时通讯云，SDK会收集您的设备信息（可能包括IMEI、IMSI、设备MAC地址、Android
      Id、设备型号、网络类型、无线网络列表、无线网络MAC地址、无线网络名称、传感器信息、SIM序列号信息）、位置信息，隐私连接说明
      https://docs-im.easemob.com/start/start。
    </div>
    <div class="deal-text1">
      2）如您在汇智启航科技网站如要与客服进行即使通讯联系，需要使用上海兆言网络科技有限公司
      所提供的声网SDK（io.agora），SDK会收集您的设备信息（可能包括IMEI、IMSI、设备MAC地址、Android
      Id、设备型号、网络类型、无线网络列表、无线网络MAC地址、无线网络名称、传感器信息、SIM序列号信息）、位置信息，隐私连说明
      https://docportal.shengwang.cn/cn/Real-time-Messaging/api-ref?platform=All%20Platforms
    </div>
    <div class="deal-text1">3、消息推送</div>
    <div class="deal-text1">
      如您在汇智启航科技网站如要收到推送消息，需要使用深圳市和讯华谷信息技术有限公司所提供的极光推送，SDK会收集您的设备信息（可能包括IMEI、IMSI、设备MAC地址、Android
      Id、设备型号、网络类型、无线网络列表、无线网络MAC地址、无线网络名称、传感器信息、SIM序列号信息）、位置信息，隐私连接说明
      https://www.jiguang.cn/license/privacy。
    </div>
    <div class="deal-text1">4、分享</div>
    <div class="deal-text1">
      如您在汇智启航科技网如要分享商品，需要使用北京友盟网络科技有限公司所提供的友盟分享SDK（com.umeng），SDK会收集您的设备信息（可能包括IMEI、IMSI、设备MAC地址、Android
      Id、设备型号、网络类型、无线网络列表、无线网络MAC地址、无线网络名称、传感器信息、SIM序列号信息）、位置信息，隐私连接说明
      https://developer.umeng.com/docs/128606/cate/128606.
    </div>
    <div class="deal-text2">八、我们如何处理未成年人的信息</div>
    <div class="deal-text1">在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本隐私权政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或我们提供的信息。</div>
    <div class="deal-text1">如您是儿童的父母或其他监护人，请您关注您监护的儿童是否是在取得您的授权同意之后使用我们的产品及/或服务。</div>
    <div class="deal-text2">九、本隐私权政策如何更新</div>
    <div class="deal-text1">由于我们的服务与技术是一直随着您及其他用户的需求持续不断地更新和优化的，因此在新的服务及业务流程变更时，我们可能会更新我们的隐私权政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所应享有的权利。我们会在专门页面上发布对本政策所做的任何变更。</div>
    <div class="deal-text1">对于重大变更，我们还会提供更为显著的通知（包括我们会通过汇智启航科技网站页面公示的方式进行通知或向您提供弹窗提示）。</div>
    <div class="deal-text2">本政策所指的重大变更包括但不限于：</div>
    <div class="deal-text1">1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</div>
    <div class="deal-text1">2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；</div>
    <div class="deal-text1">3、个人信息共享、转让或公开披露的主要对象发生变化；</div>
    <div class="deal-text1">4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</div>
    <div class="deal-text1">5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化。</div>
    <div class="deal-text2">十、如何联系我们</div>
    <div class="deal-text1">您可以通过以下方式与我们联系，我们将在15天内回复您的请求：</div>
    <div class="deal-text1">1、如对本政策内容有任何疑问、意见或建议，您可以通过网站客服电话与我们联系；</div>
    <div class="deal-text1">如发现个人信息可能被泄露，您可以通过汇智启航科技网站联系电话找到客服电话，进行投诉举报。</div>
    <div class="deal-text3">深圳市汇智启航科技有限责任公司</div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.deal {
text-align: left;
  padding: 20px 10px;
  box-sizing: border-box;
  .deal-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .deal-text {
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px;
  }

  .deal-text1,
  .deal-text2, .deal-text3 {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: 400;
    word-break: break-all;
  }

  .deal-text2 {
    font-size: 18px;
    font-weight: bold;
  }

  .deal-text3 {
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
  }
}
</style>