<template>
  <div class="home">
    <div class="carousel-swiper1">
      <navBar :tab="tab"></navBar>
      <el-carousel direction="horizontal" :interval="3000" arrow="never">
        <el-carousel-item v-for="(info, num) in 5" :key="num">
          <div class="swiper1-div" v-if="num == 0">
            <div class="swiper1-div-block">
              <div class="swiper1-div-block-one">
                <div class="swiper1-div-block-one-title">
                  金融衍生品行业一站式服务系统提供商
                </div>
                <div class="swiper1-div-block-one-text">
                  A LEADER IN SUPPLY CHAIN FINTECH
                </div>

                <div class="swiper1-div-block-one-face">
                  专业、创新、诚信、品优
                </div>
              </div>
              <div class="swiper1-div-block-two">
                <div
                  class="swiper1-div-block-two-list"
                  v-for="(item, index) in 3"
                  :key="index"
                >
                  <div class="swiper1-div-block-two-list-1">
                    <div class="swiper1-div-block-two-list-1-num">
                      {{ index == 0 ? "666" : index == 1 ? "32,584" : "100" }}
                    </div>
                    <div class="swiper1-div-block-two-list-1-img">+</div>
                  </div>

                  <div class="swiper1-div-block-two-list-2">{{ index == 0? '服务核心企业': index == 1? '服务会员数量': '金融衍生产品数量' }}</div>
                </div>
              </div>
            </div>

            <img class="swiper1-div-img" src="../assets/swiper1.png" alt="" />
          </div>

          <div class="swiper2-div" v-if="num == 1">
            <div class="swiper2-div-block">
              <div class="swiper2-div-block-first">
                <div class="swiper2-div-block-first-title">产品服务</div>
                <div class="swiper2-div-block-first-text">PRODUCT SERVICE</div>
              </div>

              <div class="swiper2-div-block-second">
                <div
                  class="swiper2-div-block-second-list"
                  v-for="(item, index) in 4"
                  :key="index"
                >
                  <div class="swiper2-div-block-second-list-title">
                    {{
                      index == 0
                        ? "标准AI产品"
                        : index == 1
                        ? "定制风控服务"
                        : index == 2
                        ? "数字化转型赋能"
                        : "小微解决方案"
                    }}
                  </div>
                  <img
                    class="swiper2-div-block-second-list-img"
                    :src="
                      index == 0
                        ? require('../assets/swiper1-1-icon_1.png')
                        : index == 1
                        ? require('../assets/swiper1-1-icon_2.png')
                        : index == 2
                        ? require('../assets/swiper1-1-icon_3.png')
                        : require('../assets/swiper1-1-icon_4.png')
                    "
                  />
                  <div class="swiper2-div-block-second-list-text">
                    {{
                      index == 0
                        ? "基于大数据技术与多年AI建模经验打造多款模型，覆盖信贷业务全部流程。"
                        : index == 1
                        ? "为金融机构提供定制化精准获客、精准风控及精准运营服务。"
                        : index == 2
                        ? "通过化精准获客、智能风控平台及咨询推动金融机构数字化转系。"
                        : "通过风险评分、场景化产品等咨建设金融机构小微业务能力。"
                    }}
                  </div>
                </div>
              </div>
            </div>
            <img class="swiper2-div-img" src="../assets/swiper1-1.png" alt="" />
          </div>

          <div class="swiper3-div" v-if="num == 2">
            <div class="swiper3-div-block">
              <div class="swiper3-div-block-first">
                <div class="swiper3-div-block-first-title">汇智启航优势</div>
                <div class="swiper3-div-block-first-text">PRODUCT SERVICE</div>
              </div>

              <div class="swiper3-div-block-text">
                汇智启航为近200家银行、消费金融公司、互联网平台等持牌机构<span
                  style="font-size: 21px; margin: 0 5px"
                  >提供数字化转型升级服务</span
                >，并与持牌征信机构、合规大数据公司和IT系统服务商等建立紧密合作，<span
                  style="font-size: 21px; margin: 0 5px"
                  >深度赋能整个金融科技产业生态。</span
                >
              </div>

              <div class="swiper3-div-block-second">
                <div
                  class="swiper3-div-block-second-list"
                  v-for="(item, index) in 4"
                  :key="index"
                >
                  <div class="swiper3-div-block-second-list-title">
                    {{
                      index == 0
                        ? "甲方风控基因"
                        : index == 1
                        ? "合规引领市场"
                        : index == 2
                        ? "闭环服务能力"
                        : "白盒服务方式"
                    }}
                  </div>
                  <div class="swiper3-div-block-second-list-lint"></div>
                  <div class="swiper3-div-block-second-list-text">
                    {{
                      index == 0
                        ? "完备的甲方风控实战经验聚焦业务实质的服务理念"
                        : index == 1
                        ? "坚持甲方标准做乙方业务以合规模式服务持牌机构"
                        : index == 2
                        ? "端到端辅助运营体系建设 模块化解决方案能力输出"
                        : "产品服务全流程开放透明与客户分享实战经验沉淀"
                    }}
                  </div>

                  <img
                    class="swiper3-div-block-second-list-img"
                    :src="
                      index == 0
                        ? require('../assets/swiper1-2-icon_1.png')
                        : index == 1
                        ? require('../assets/swiper1-2-icon_2.png')
                        : index == 2
                        ? require('../assets/swiper1-2-icon_3.png')
                        : require('../assets/swiper1-2-icon_4.png')
                    "
                  />
                </div>
              </div>
            </div>
            <img class="swiper3-div-img" src="../assets/swiper1-2.png" alt="" />
          </div>

          <div class="swiper4-div" v-if="num == 3">
            <div class="swiper4-div-block">
              <div class="swiper4-div-block-first">
                <div class="swiper4-div-block-first-title">数据中心服务</div>
                <div class="swiper4-div-block-first-text">
                  DATA CENTER SERVICES
                </div>
              </div>

              <div class="swiper4-div-block-text">
                面向未来的新T基础架构，一体化、智能化、可视化运维管理平台，<span
                  style="font-size: 21px; margin: 0 5px"
                  >运、营、管、控</span
                >全方位一站式管理服务
              </div>

              <div class="swiper4-div-block-second">
                <div
                  class="swiper4-div-block-second-list"
                  v-for="(item, index) in 3"
                  :key="index"
                >
                  <img
                    class="swiper4-div-block-second-list-img"
                    :src="
                      index == 0
                        ? require('../assets/swiper1-3-icon_1.png')
                        : index == 1
                        ? require('../assets/swiper1-3-icon_2.png')
                        : require('../assets/swiper1-3-icon_3.png')
                    "
                  />

                  <div class="swiper4-div-block-second-list-title">
                    {{
                      index == 0
                        ? "托管服务"
                        : index == 1
                        ? "管理服务"
                        : "增值服务 "
                    }}
                  </div>

                  <div class="swiper4-div-block-second-list-text">
                    {{
                      index == 0
                        ? "高度安全、可靠和容错的数据中心环境提供机柜租赁、场地租赁、BCP席位等"
                        : index == 1
                        ? "7*24小时托管运维服务，业务连续性灾备解决方案，系统、网络、数据、中间件等一站式管理服务，覆盖全IT生命周期"
                        : "专业业务咨询、金融行业解决方案，专属定制服务为金融客户订制专属一站式服务菜单"
                    }}
                  </div>
                </div>
              </div>
            </div>
            <img class="swiper4-div-img" src="../assets/swiper1-3.png" alt="" />
          </div>

          <div class="swiper5-div" v-if="num == 4">
            <div class="swiper5-div-block">
              <div class="swiper5-div-block-first">
                <div class="swiper5-div-block-first-title">
                  客户第一 服务为先
                </div>
                <div class="swiper5-div-block-first-text">
                  CUSTOMERFIRST SERVICE FIRST
                </div>
              </div>

              <div class="swiper5-div-block-text">
                简单温暖，专业高效 —— 价值观
              </div>

              <div class="swiper5-div-block-second">
                <div class="swiper5-div-block-second-right">
                  <div
                    class="swiper5-div-block-second-right-list"
                    v-for="(item, index) in 2"
                    :key="index"
                  >
                    <div class="swiper5-div-block-second-right-list-title">
                      {{
                        index == 0 ? "不忘初心，简单坚守" : "创新图变，奋楫争先"
                      }}
                    </div>

                    <div class="swiper5-div-block-second-right-list-text">
                      {{
                        index == 0
                          ? "汇智启航科技自诞生之日起。经过多年运营，汇智启航科技的业务模式已获得包括行业专家、律师事务所、金融机构、监管机构、行业协会及超过百家上市公司的认可与好评，树立了正直的企业形象。"
                          : "富匠科技坚持供应链金融科技创新，重磅推出多项全国首创金融科技服务，包括首张应收账款电子债权凭证、首张供应链票据、首次将区块链与凭证业务结合、首单不特定原始权益人ABS储架发行等。汇智启航科技多次实现“从0到1”的突破，始终走在供应链金融科技前沿。"
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img class="swiper5-div-img" src="../assets/swiper1-4.png" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="carousel-swiper2">
      <el-carousel
        direction="horizontal"
        :interval="5000"
        arrow="always"
        @change="changeswiperItem"
        :initial-index="swiperItemIndex"
        :autoplay="autoplayShow"
      >
        <el-carousel-item v-for="(info, num) in 5" :key="num">
          <div class="swiper1-div" v-if="num == 0">
            <div
              class="swiper1-div-block"
              v-if="swiperItemIndex == num ? true : false"
            >
              <div
                class="swiper1-div-block-list"
                v-for="(item, index) in 4"
                :key="index"
              >
                <div class="swiper1-div-block-list-title">
                  {{
                    index == 0
                      ? "标准Al产品"
                      : index == 1
                      ? "风险类评分"
                      : index == 2
                      ? "联合建模服务"
                      : "KYC系列评分"
                  }}
                </div>

                <div class="swiper1-div-block-list-text">
                  {{
                    index == 0
                      ? "团队拥有多年实战积累的业务经验，基于金融业务各类场景需求对大数据进行深度挖掘，结合先进的人工智能技术，打造出多款风险画像和模型产品，可覆盖金融机构业务全流程。"
                      : index == 1
                      ? "欺诈风险评分、信用风险评分、多头借贷风险评分等风险系列产品，可有效帮助金融机构精准识别高风险人群，提高风控审批效率，降低坏账率，提升综合风控能力。也可用于贷中风险预警，提前察觉客户风险变化，有效避免损失。"
                      : index == 2
                      ? "汇智启航科提供多种方式的联合建模服务，可以更有针对性的为客户提供在特定场景、客群下的模型定制服务。基于我们对各类模型特征的深刻理解与洞察，以及对机器学习算法的应用，能够极大提升联合建模效率，做到事半功倍。"
                      : "客户价值评估指数、资产评估指数等KYC系列产品，从多维度描述用户行为画像，综合判断个人财富累积值。可评估用户价值，应用于精准营销、客群分层、额度策略、休眠客户激活等场景，帮助业务实现精细化运营。"
                  }}
                </div>
              </div>
            </div>

            <img class="swiper1-div-img" src="../assets/swiper2.png" alt="" />
          </div>

          <div class="swiper2-div" v-if="num == 1">
            <div
              class="swiper2-div-block animation"
              v-if="swiperItemIndex == num ? true : false"
            >
              <div
                class="swiper2-div-block-list"
                v-for="(item, index) in 4"
                :key="index"
              >
                <div class="swiper2-div-block-list-title">
                  {{
                    index == 0
                      ? "定制风控服务"
                      : index == 1
                      ? "定制模型"
                      : index == 2
                      ? "定制策略"
                      : "资金资产 "
                  }}
                </div>
                <div class="swiper2-div-block-list-text">
                  {{
                    index == 0
                      ? "定制风控服务包括营销响应模型、拒件捞回、定制建模、零售信贷业务赋能、额度策略、A卡模型验证与优化等，为金融机构提供差异化、定制化，覆盖个人消费信贷业务全流程服务的整体解决方案。"
                      : index == 1
                      ? "基于领先的建模能力和对复杂AI算法的精准驾驭，可根据实际业务和场景，为合作机构提供从样本标签提取、底层变量挖掘、模型算法优选、模型开发验证到应用指标监控的全流程、体系化的定制化场景模型开发，覆盖贷前、贷中、贷后等信贷全流程各个环节，如反欺诈、风险评估、额度定价、人行征信、营销获客、A卡模型优化、信用卡高风险套现模型等，助力机构实现精细化风控运营。汇智启航的定制建模解决方案周期短、模式新、上线快，基本4-8周可完成模型上线，目前已被多家合作机构长时间线上使用，效果已经过理论和实践的双重验证。"
                      : index == 2
                      ? "风控策略是信贷业务中非常重要的一环，监管层面、机构层面、风控层面均需遵循一定的原则，无论是业务冷启动还是发展阶段，均对策略的有效性、稳定性有非常严格的要求。汇智启航拥有丰富风控体系从0到1的搭建经验，可以为金融机构提供有针对性的，适用于各场景的规则及规则集的制定、策略执行顺序分析及优化等服务，同时协助机构完成不同渠道风控策略的定制化，助力金融机构实现自主风控，提高风险把控力。"
                      : "为机构提供行业风险趋势分析、多资产分析等业务调研方案，定制化资产收益于组合建议、流量接入评估与规划等，解决金融机构对信贷资产的风险和收益兼顾难题，助力金融机构进行信贷资产组合管理，实现持续收益。"
                  }}
                </div>
              </div>
            </div>
            <img class="swiper2-div-img" src="../assets/swiper2-1.png" alt="" />
          </div>

          <div class="swiper3-div" v-if="num == 2">
            <div
              class="swiper3-div-block animation"
              v-if="swiperItemIndex == num ? true : false"
            >
              <div
                class="swiper3-div-block-list"
                v-for="(item, index) in 4"
                :key="index"
              >
                <div class="swiper3-div-block-list-title">
                  {{
                    index == 0
                      ? "数字化转型赋能"
                      : index == 1
                      ? "智慧精准营销-流量获客"
                      : index == 2
                      ? "智能风控平台"
                      : "汇智启航咨询"
                  }}
                </div>
                <div class="swiper3-div-block-list-text">
                  {{
                    index == 0
                      ? "定制风控服务包括营销响应模型、拒件捞回、定制建模、零售信贷业务赋能、额度策略、A卡模型验证与优化等，为金融机构提供差异化、定制化。覆盖个人消费信贷业务全流程服务的整体解决方案。"
                      : index == 1
                      ? "借助大数据技术和用户特征捕捉能力，通过精准客群分类、海量数据画像挖掘、模型流程设计，产出意愿模型，结合融慧风险预筛选逻辑，共同形成白名单产品，并将白名单应用到获客领域，可有效兼顾意愿与风险，为客户匹配腾讯系、头条系等丰富优质的流量资源并进行精准投放，助力金融机构提升获客效果。"
                      : index == 2
                      ? "经过多年的技术打磨，融慧以人工智能，大数据技术为基础，以关联网络、决策引擎、机器学习、图算法等技术为核心打造了征信指标管理平台、资产质量监控平台、模型监控管理平台、数据管理平台等一系列信贷风控系统类产品，形成了独具汇智启航特色的智能风控中台，为金融机构的全流程、全周期业务风控决策提供辅助支持，帮助金融机构实现降本增效"
                      : "汇智启航咨询拥有一支高学历、高素质的专家顾问团队，成员均毕业于密歇根州立大学、北京大学、清华大学、UCL等海内外名校，且曾任职于美国运通、PWC、德勤、百度金融、宜信等知名企业，具备多年信用卡、消费金融等领域的风控实战经验。汇智启航的咨询团队可为金融机构提供闭环能力驱动的全方位咨询及落地服务。"
                  }}
                </div>
              </div>
            </div>
            <img class="swiper3-div-img" src="../assets/swiper2-2.png" alt="" />
          </div>

          <div class="swiper4-div" v-if="num == 3">
            <div
              class="swiper4-div-block animation"
              v-if="swiperItemIndex == num ? true : false"
            >
              <div class="swiper4-div-block-header">小微综合解决方案</div>
              <div class="swiper4-div-block-heade1">中小微行业现状</div>
              <div
                class="swiper4-div-block-list"
                v-for="(item, index) in 3"
                :key="index"
              >
                <div class="swiper4-div-block-list-title">
                  {{
                    index == 0
                      ? "1.企业信息收集难"
                      : index == 1
                      ? "2.风险难控制"
                      : "3.数据接入频繁"
                  }}
                </div>
                <div class="swiper4-div-block-list-text">
                  {{
                    index == 0
                      ? "企业发票和纳税数据收集繁琐，容易错失，贷前筛查，贷后监控难以周全。传统尽调方式是由人工完成，效率低，成本高。"
                      : index == 1
                      ? "人工操作中可能存在的道德风险和欺诈风险:贷后监管精准度低，不良率，坏账率无法有效控制。"
                      : "需要接入多发数据源，技术重复对接，数据清洗，加工处理工作繁重。"
                  }}
                </div>
              </div>

              <div class="swiper4-div-block-heade2">
                汇智启航致力于整合自身现有的资源与能力，为汇智启航机构小微业务场景
              </div>
              <div class="swiper4-div-block-heade2">
                提供基础信息核验、风险模型评分、跨境电商等场景化产品、风控整体性产品及服务解决方案
              </div>
              <div class="swiper4-div-block-heade2">
                大力支持汇智启航机构小微业务能力建设，确保业务可持续化稳健发展。
              </div>
            </div>
            <img class="swiper4-div-img" src="../assets/swiper2-3.png" alt="" />
          </div>

          <div class="swiper5-div" v-if="num == 4">
            <div
              class="swiper5-div-block animation"
              v-if="swiperItemIndex == num ? true : false"
            >
              <div class="swiper5-div-block-header">EA优势</div>
              <div
                class="swiper5-div-block-list"
                v-for="(item, index) in 7"
                :key="index"
              >
                <div class="swiper5-div-block-list-title">
                  {{
                    index == 0
                      ? "1、融合盈利系统的交易精华，把经过验证的交易策略运用于实盘交易"
                      : index == 1
                      ? "2、克服人性中的弱点，避免情绪化操作"
                      : index == 2
                      ? "3、由电脑自动下单，自动适应价格变动和趋势变动，可以更敏感 快速地响应价格变动和趋势变化，保证更快的下单。"
                      : index == 3
                      ? "4、电脑24小时监控行情，无须人工操作，交易时外汇投资者须盯盘"
                      : index == 4
                      ? "5、严格控制风险，杜绝过度交易以及不严格执行止损等问题"
                      : index == 5
                      ? "6、智能化的编程，省略了分析面的过程，分析速度远远大于人类"
                      : index == 6
                      ? "7、保障盈利的综合率，EA智能外汇全自动交易系统最重要的优 点，稳定盈利。融合了众多外汇高手的智慧与经验，严格的止 损和风险控制。"
                      : ""
                  }}
                </div>
              </div>
            </div>
            <img class="swiper5-div-img" src="../assets/swiper2-4.png" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="carousel-swiper3">
      <div class="carousel-swiper3-block" v-if="isShow == true">
        <div class="carousel-swiper3-block-title">公司简介</div>
        <div class="carousel-swiper3-block-text">COMPANY PROFILE</div>

        <div class="carousel-swiper3-block-span">金融供应链金融科技领跑者</div>

        <div class="carousel-swiper3-block-span1">
          汇智启航科技公司孵化于千亿级产业集团，为业内领先的供应链金融科技服务公司，是国家高新技术企业，具备上海票据交易所接入资质的科技平台，也是广东省供应链金融“监管沙盒”试点企业之一，获得欧洲金融陶朱奖、中物联+佳区块链应用企业等荣誉。简单汇致力干通过科技手段推动商业信用的价值传递，提高企业供应链的整体
          竞争力。凭借多年实体经验与产业智慧，围绕核心企业产业链，运用大数据、区块链、人工智能等先进科技手段，搭建出可拆分流转的应收账款电子债权凭证金单、跨境金单、供应链票据、微贷、汇能云、汇科云等主要产品，为全国不同行业、场景、供应链主体定制综合供应链金融科技解决方案。
        </div>
      </div>

      <div class="carousel-swiper3-block1" v-if="isShow == false">
        <div class="carousel-swiper3-block-title">公司简介</div>
        <div class="carousel-swiper3-block-text">COMPANY PROFILE</div>


        <div class="carousel-swiper3-block-span1">
          深圳市汇智启航科技有限公司作为一家专注于金融衍生品行业一站式服务系统提供商，我们致力于为全球金融机构、投资机构及专业交易者提供全方位、定制化的解决方案，助力客户在瞬息万变的金融市场中稳健前行。 <br/>
          
        </div>
        <div class="carousel-swiper3-block-span1" >
          自2021年4月22日成立以来，汇智启航科技便深耕于金融衍生品技术领域，凭借卓越的技术创新能力、深厚的行业理解以及对市场动态的敏锐洞察，我们成功打造了多套高效、稳定、安全的金融衍生品系统。这些系统不仅覆盖了期货、期权、互换、远期合约等主流金融衍生品，还不断拓展至更多复杂衍生品领域，以满足市场日益增长的多元化需求。
        </div>
      </div>

      <img class="carousel-swiper3-img" src="../assets/swiper3.png" />
    </div>

    <div class="carousel-swiper4">
      <img class="carousel-swiper4-img" src="../assets/swiper4.png" />

      <div class="carousel-swiper4-block">
        <div class="carousel-swiper4-block-first">
          <div class="block-first-div">
            <div class="block-first-div-title">风控讲堂</div>
            <div class="block-first-div-text">RISK MANAGEMENT</div>
          </div>
        </div>

        <div class="carousel-swiper4-block-second">
          <div
            class="block-second-list"
            v-for="(item, index) in 3"
            :key="index"
            @click="prevention(index)"
          >
            <div class="block-second-list-play">
              <img
                class="block-second-list-play-back"
                alt=""
                :src="
                  index == 0
                    ? require('../assets/pneumatic1.png')
                    : index == 1
                    ? require('../assets/pneumatic2.png')
                    : require('../assets/pneumatic3.png')
                "
              />
              <img
                class="block-second-list-play-img"
                src="../assets/swiper4_1_icon.png"
              />
            </div>

            <div class="block-second-list-div">
              <div class="block-second-list-div-title">
                {{
                  index == 0
                    ? "外汇是什么？外汇基础概念讲解"
                    : index == 1
                    ? "外汇入门讲解"
                    : "《MT4EA》5、风控EA"
                }}
              </div>
              <div class="block-second-list-div-text">
                {{ index == 0 ? "356" : index == 1 ? "633" : "284" }}人观看
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-swiper5">
      <img class="carousel-swiper5-img" src="../assets/swiper5.png" />

      <div class="carousel-swiper5-block">
        <div class="carousel-swiper5-block-first">
          <div class="block-first-div">
            <div class="block-first-div-title">一起成长，追梦理想</div>
            <div class="block-first-div-text">
              共事最亲民的老板，遇见最极客的技术牛人，加入富有激情、勇于创新的青年团队
            </div>
          </div>
        </div>

        <div class="carousel-swiper5-block-second">
          <div
            class="block-second-list"
            v-for="(item, index) in 3"
            :key="index"
          >
            <img
              class="block-second-list-img"
              :src="
                index == 0
                  ? require('../assets/swiper5-1_icon.png')
                  : index == 1
                  ? require('../assets/swiper5-2_icon.png')
                  : require('../assets/swiper5-3_icon.png')
              "
              alt=""
            />

            <div class="block-second-list-title">
              {{
                index == 0 ? "薪资待遇" : index == 1 ? "员工福利" : "职业规划"
              }}
            </div>
            <div class="block-second-list-text">
              {{
                index == 0
                  ? "业内有竞争力的薪酬、激励奖金计划、年终优秀员工奖励制度，对优秀的人才将给予公司股票期权，让每一位员工充分实现自身的价值。"
                  : index == 1
                  ? "五险一金、带薪假期、年度体检、年度旅游、团队聚餐、多样化的文体娱乐活动与舒适的办公环境。快乐地工作生活，我们不断在努力。"
                  : "入职培训、技能培训、定期的内部分享交流与团队拓展培训。公司处于快速发展期与扩大阶段，我们为优秀的员工提供岗位晋升的机会。 "
              }}
            </div>
          </div>
        </div>

        <div class="carousel-swiper5-block-thirdly" @click="positionClcik">
          查看职位
        </div>
      </div>
    </div>

    <div class="carousel-swiper6">
      <div class="carousel-swiper6-block">
        <div class="carousel-swiper6-block-left">
          <div class="block-left-div">
            <img
              class="block-left-div-img"
              src="../assets/swiper6_icon_phone.png"
            />
            <div class="block-left-div-phone">联系电话</div>
            <div class="block-left-div-num">400-609-8779</div>

            <div class="block-left-div-num1">致电/在线客服时间：8:30-18:30</div>
          </div>
        </div>
        <div class="carousel-swiper6-block-right">
          <div class="block-left-div">
            <img
              class="block-left-div-dizhi"
              src="../assets/swiper6_icon_dizhi.png"
            />
            <div class="block-left-div-phone">总部地址</div>
            <div class="block-left-div-num" style="font-size: 24px">
              深圳市龙岗区平湖街道禾花社区平吉大道321号A栋313
            </div>
          </div>
        </div>
      </div>

      <div class="carousel-swiper6-tab">
        <tab-bar></tab-bar>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "../components/nav-bar/nav-bar.vue";
import tabBar from "../components/tab-bar/tab-bar.vue";

var throttle = require("../plugins/throttle"); // 防抖

export default {
  components: {
    navBar,
    tabBar,
  },
  data() {
    return {
      // 滚动时间
      timeOut: null,
      tab: 0,
      // 页面显示或者隐藏
      isShow: false,
      // 新增页面切换对比下标
      swiperItemIndex: 0,
      // 切换到第二部分才开发自动轮播图
      autoplayShow: false,
      // 默认定义一个变量记录当前滚动
      scrollSwiper: false,
      // 页面底部点击跳转对应的轮播图
      swiperIndex: 0,
    };
  },
  created() {
    if (localStorage.getItem("home")) {
      console.log("查询对应轮播图", localStorage.getItem("home"));
      this.swiperIndex = Number(localStorage.getItem("home"));

      this.changeItem(1);
    }
  },
  methods: {
    // 滚动方法  进行鼠标滚动上下
    rollScroll(event) {
      this.everyTime(event);
    },
    // // 每次开始开发
    everyTime: throttle.throttle(function (event) {
      console.log("滚动开发");
      let _that = this;
      // chrome、ie使用的wheelDelta，火狐使用detail
      let scrollVal = event.wheelDelta || event.detail;

      // 节流
      if (!_that.timeOut) {
        _that.timeOut = setTimeout(() => {
          _that.timeOut = null;
          scrollVal > 0
            ? _that.$refs.carousel.prev()
            : _that.$refs.carousel.next();
        }, 300);
      } else {
      }
    }, 1900),

    // 监听轮播图滚到什么地方了
    changeItem(e) {
      console.log("第一部分轮播图每次滚动", e);
      console.log("000", this.swiperItemIndex);
      console.log("-----", this.isShow);
      // this.scrollSwiper = false;

      // if (e == 2) {
      //   this.isShow = true;
      // } else if (e == 1) {
      //   this.autoplayShow = true;
      //   this.swiperItemIndex = 0;

      //   localStorage.removeItem("home");
      // } else {
      //   setTimeout(() => {
      //     this.isShow = false;
      //   }, 100);
      //   this.autoplayShow = false;
      //   this.swiperItemIndex = 8;
      // }
    },
    // 点击对应的风控事件
    prevention(index) {
      if (index == 0) {
        window.open(
          "https://haokan.baidu.com/v?pd=wisenatural&vid=4552773370316071104"
        );
      } else if (index == 1) {
        window.open(
          "https://haokan.baidu.com/v?pd=wisenatural&vid=3489526177582554702"
        );
      } else {
        window.open("https://www.bilibili.com/video/BV1cL411b7zE");
      }
    },

    // 第二轮播图切换
    changeswiperItem(e) {
      console.log("下标", e);
      this.swiperItemIndex = e;
    },

    // 查看职位
    positionClcik() {
      this.$router.push({
        path: "/personalus",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  // 走马灯内部循环 1
  .carousel-swiper1 {
    width: 100%;
    height: 100vh;
    position: relative;
    /deep/ .el-carousel__container {
      height: 90vh !important;
      width: 100%;
    }

    /deep/ .el-carousel__indicators {
      // 指示器
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
    /deep/ .el-carousel__button {
      // 指示器按钮
      width: 30px;
      height: 3px;
      border: none;
      background-color: rgba(0, 0, 0, 0.3);
      margin-right: 9px;
    }
    /deep/ .el-carousel__indicator--vertical {
      padding: 0;
    }
    /deep/ .is-active .el-carousel__button {
      // 指示器激活按钮
      background: #ffffff;
    }
    .swiper1-div {
      width: 100%;
      height: 100%;
      position: relative;
      .swiper1-div-block {
        font-size: 55px;
        font-family: Helvetica;
        color: #ffffff;
        position: absolute;
        z-index: 5;
        top: 137px;
        left: 307px;
        text-align: left;
        .swiper1-div-block-one {
          .swiper1-div-block-one-title {
            font-size: 55px;
            font-family: Helvetica;
            color: #ffffff;
            letter-spacing: 1px;
          }

          .swiper1-div-block-one-text {
            font-size: 35px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.2;
            position: absolute;
            top: 25px;
            letter-spacing: 1px;
          }

          .swiper1-div-block-one-face {
            font-size: 33px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-top: 6px;
          }
        }

        .swiper1-div-block-two {
          height: 100px;
          margin-top: 124px;
          display: flex;
          // justify-content: space-between;

          .swiper1-div-block-two-list {
            height: 100%;
            margin-right: 100px;

            .swiper1-div-block-two-list-1 {
              display: flex;
              justify-content: center;
              width: 100%;

              .swiper1-div-block-two-list-1-num {
                font-size: 43px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;

                color: #ffffff;
              }

              .swiper1-div-block-two-list-1-img {
                font-size: 31px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #f60646;
                margin-top: -10px;
                margin-left: 5px;
              }
            }

            .swiper1-div-block-two-list-2 {
              font-size: 22px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              margin-top: 5px;
            }
          }
        }
      }

      .swiper1-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .swiper2-div {
      width: 100%;
      height: 1080px;
      position: relative;
      .swiper2-div-block {
        position: absolute;
        z-index: 5;
        width: 100%;
        top: 18%;
        .swiper2-div-block-first {
          width: 340px;
          display: flex;
          justify-content: center;
          margin: 0 auto;
          .swiper2-div-block-first-title {
            font-size: 55px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            letter-spacing: 1px;
          }

          .swiper2-div-block-first-text {
            font-size: 35px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.2;
            position: absolute;
            top: 42px;
            letter-spacing: 1px;
          }
        }

        .swiper2-div-block-second {
          display: flex;
          margin-top: 60px;
          justify-content: center;

          .swiper2-div-block-second-list {
            width: 232px;
            height: 275px;
            background: linear-gradient(141deg, #e6f0fe 0%, #e6ebfe 100%);
            border-radius: 20px;
            border: 1px solid #ffffff;
            margin-right: 68px;
            cursor: pointer;

            .swiper2-div-block-second-list-title {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #333333;
              margin-top: 40px;
            }

            .swiper2-div-block-second-list-img {
              width: 86px;
              height: 82px;
              margin: 11px auto 15px;
            }
            .swiper2-div-block-second-list-text {
              width: 190px;
              height: 75px;
              font-size: 15px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 25px;
              text-align: left;
              margin: 0 auto;
            }
          }

          .swiper2-div-block-second-list:last-child {
            margin-right: 68px;
          }
        }
      }

      .swiper2-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 1080px;
        object-fit: fill;
      }
    }

    .swiper3-div {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper3-div-block {
        position: absolute;
        z-index: 5;
        width: 100%;
        top: 18%;
        .swiper3-div-block-first {
          width: 340px;
          display: flex;
          justify-content: center;
          margin: 0 auto;
          .swiper3-div-block-first-title {
            font-size: 55px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            letter-spacing: 1px;
          }

          .swiper3-div-block-first-text {
            font-size: 35px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            opacity: 0.2;
            position: absolute;
            top: 42px;
            letter-spacing: 1px;
          }
        }

        .swiper3-div-block-text {
          width: 944px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 36px;
          text-align: left;
          margin: 0 auto;
          margin-top: 37px;
        }

        .swiper3-div-block-second {
          display: flex;
          margin-top: 60px;
          justify-content: center;

          .swiper3-div-block-second-list {
            width: 232px;
            height: 275px;
            background: linear-gradient(141deg, #e6f0fe 0%, #e6ebfe 100%);
            border-radius: 20px;
            border: 1px solid #ffffff;
            margin-right: 68px;
            cursor: pointer;
            position: relative;

            .swiper3-div-block-second-list-title {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #ffffff;
              margin-top: 72px;
              position: relative;
              z-index: 6;
            }

            .swiper3-div-block-second-list-lint {
              width: 76px;
              height: 3px;
              background: #ffffff;
              margin: 26px auto 24px;
              position: relative;
              z-index: 6;
            }

            .swiper3-div-block-second-list-text {
              width: 176px;
              height: 50px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 25px;
              text-align: left;
              margin: 0 auto;
              position: relative;
              z-index: 6;
            }

            .swiper3-div-block-second-list-img {
              width: 100%;
              height: 100%;
              position: absolute;
              display: flex;
              top: 0;
            }
          }

          .swiper3-div-block-second-list:last-child {
            margin-right: 68px;
          }
        }
      }

      .swiper3-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
    .swiper4-div {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper4-div-block {
        position: absolute;
        z-index: 5;
        width: 100%;
        top: 18%;
        .swiper4-div-block-first {
          width: 340px;
          display: flex;
          justify-content: center;
          margin: 0 auto;
          .swiper4-div-block-first-title {
            font-size: 55px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            letter-spacing: 1px;
          }

          .swiper4-div-block-first-text {
            font-size: 35px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            opacity: 0.2;
            position: absolute;
            top: 42px;
            letter-spacing: 1px;
          }
        }

        .swiper4-div-block-text {
          width: 781px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 36px;
          text-align: left;
          margin: 0 auto;
          margin-top: 37px;
        }

        .swiper4-div-block-second {
          display: flex;
          margin-top: 82px;
          justify-content: center;

          .swiper4-div-block-second-list {
            width: 259px;
            margin-right: 68px;
            cursor: pointer;
            position: relative;
            .swiper4-div-block-second-list-img {
              width: 212px;
              height: 179px;
            }

            .swiper4-div-block-second-list-title {
              font-size: 24px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              margin-top: 23px;
              margin-bottom: 12px;
              position: relative;
              z-index: 6;
            }

            .swiper4-div-block-second-list-text {
              width: 259px;
              height: 84px;
              font-size: 15px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 26px;

              text-align: left;
              margin: 0 auto;
              position: relative;
              z-index: 6;
            }
          }

          .swiper4-div-block-second-list:last-child {
            margin-right: 68px;
          }
        }
      }

      .swiper4-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .swiper5-div {
      width: 100%;
      height: 100%;
      position: relative;

      .swiper5-div-block {
        position: absolute;
        z-index: 5;
        width: 100%;
        top: 18%;
        .swiper5-div-block-first {
          display: flex;
          justify-content: center;
          margin: 0 auto;
          .swiper5-div-block-first-title {
            font-size: 55px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2481d7;
            letter-spacing: 1px;
          }

          .swiper5-div-block-first-text {
            font-size: 35px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2481d7;
            opacity: 0.2;
            position: absolute;
            top: 42px;
            letter-spacing: 1px;
          }
        }

        .swiper5-div-block-text {
          width: 368px;
          height: 26px;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 26px;
          text-align: left;
          margin: 0 auto;
          margin-top: 37px;
        }

        .swiper5-div-block-second {
          margin-top: 82px;
          width: 100%;
          display: flex;
          justify-content: right;

          .swiper5-div-block-second-right {
            margin-right: 25%;

            .swiper5-div-block-second-right-list {
              cursor: pointer;
              position: relative;
              text-align: right;
              margin-bottom: 51px;

              .swiper5-div-block-second-right-list-title {
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                margin-bottom: 5px;
                position: relative;
                z-index: 6;
              }

              .swiper5-div-block-second-right-list-text {
                width: 443px;
                height: 104px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 26px;

                text-align: left;
                margin: 0 auto;
                position: relative;
                z-index: 6;
              }
            }

            .swiper5-div-block-second-right-list:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .swiper5-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }

  .carousel-swiper2 {
    width: 100%;
    height: 100vh;
    margin-top: -5px;
    position: relative;
    /deep/ .el-carousel__container {
      height: 100vh !important;
      width: 100%;
    }

    /deep/ .el-carousel__indicators {
      // 指示器
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
    /deep/ .el-carousel__button {
      // 指示器按钮
      width: 30px;
      height: 3px;
      border: none;
      background-color: rgba(0, 0, 0, 0.3);
      margin-right: 9px;
    }
    /deep/ .el-carousel__indicator--vertical {
      padding: 0;
    }
    /deep/ .is-active .el-carousel__button {
      // 指示器激活按钮
      background: #ffffff;
    }

    /deep/ .el-carousel__arrow--left {
      left: 10%;
      width: 60px;
      height: 60px;
      font-size: 28px;
    }

    /deep/ .el-carousel__arrow--right {
      right: 10%;
      width: 60px;
      height: 60px;
      font-size: 28px;
    }

    .swiper1-div {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .swiper1-div-block {
        width: 1150px;
        position: absolute;
        z-index: 5;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .swiper1-div-block-list {
          width: 400px;
          height: 194px;
          margin-top: 160px;
          animation: fadenum 3s;

          .swiper1-div-block-list-title {
            height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 42px;
          }

          .swiper1-div-block-list-text {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            text-align: left;
          }
        }

        .swiper1-div-block-list:nth-child(1) {
          margin-top: 0;
        }

        .swiper1-div-block-list:nth-child(2) {
          margin-top: 0;
        }
      }

      .swiper1-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: inherit;
      }
    }

    .swiper2-div {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .swiper2-div-block {
        width: 760px;
        position: relative;
        z-index: 5;
        text-align: left;
        right: 18%;
        .swiper2-div-block-list {
          margin-bottom: 35px;

          .swiper2-div-block-list-title {
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 42px;
            text-align: left;
          }

          .swiper2-div-block-list-text {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            text-align: left;
            margin-top: 12px;
            opacity: 0.7;
          }
        }

        .swiper2-div-block-list:last-child {
          margin-bottom: 0;
        }
      }

      .swiper2-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: inherit;
      }
    }

    .swiper3-div {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .swiper3-div-block {
        width: 760px;
        position: relative;
        z-index: 5;
        text-align: left;
        left: 15%;
        .swiper3-div-block-list {
          margin-bottom: 35px;
          .swiper3-div-block-list-title {
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            text-align: left;
          }

          .swiper3-div-block-list-text {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            text-align: left;
            margin-top: 12px;
            opacity: 0.7;
          }
        }

        .swiper3-div-block-list:last-child {
          margin-bottom: 0;
        }
      }

      .swiper3-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: inherit;
      }
    }

    .swiper4-div {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .swiper4-div-block {
        position: relative;
        z-index: 5;
        text-align: left;
        right: 15%;

        .swiper4-div-block-header {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }

        .swiper4-div-block-heade1 {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 76px;
          margin-top: 10px;
          opacity: 0.7;
        }
        .swiper4-div-block-list {
          margin-bottom: 35px;
          width: 400px;

          .swiper4-div-block-list-title {
            font-size: 34px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            text-align: left;
          }

          .swiper4-div-block-list-text {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            text-align: left;
            margin-top: 4px;
            opacity: 0.7;
          }
        }

        .swiper4-div-block-list:last-child {
          margin-bottom: 0;
        }

        .swiper4-div-block-heade2 {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .swiper4-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: inherit;
      }
    }

    .swiper5-div {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .swiper5-div-block {
        width: 760px;
        position: relative;
        z-index: 5;
        text-align: left;
        left: 21%;

        .swiper5-div-block-header {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 47px;
        }
        .swiper5-div-block-list {
          margin-bottom: 10px;
          width: 554px;
          .swiper5-div-block-list-title {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: left;
            line-height: 36px;
          }
        }

        .swiper5-div-block-list:last-child {
          margin-bottom: 0;
        }
      }

      .swiper5-div-img {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: inherit;
      }
    }
  }

  .carousel-swiper3 {
    width: 100%;
    height: 100vh;
    margin-top: -1px;
    position: relative;

    .carousel-swiper3-block,
    .carousel-swiper3-block1 {
      position: absolute;
      z-index: 5;
      width: 539px;
      right: 360px;
      top: 251px;
      text-align: left;

      .carousel-swiper3-block-title {
        font-size: 55px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        letter-spacing: 1px;
        text-align: center;
      }

      .carousel-swiper3-block-text {
        font-size: 35px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        opacity: 0.3;
        position: absolute;
        top: 35px;
        width: 100%;
        display: flex;
        justify-content: center;
        letter-spacing: 1px;
      }

      .carousel-swiper3-block-span {
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;
        margin-top: 35px;
      }

      .carousel-swiper3-block-span1 {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
        margin-top: 55px;
        text-align: left;
      }
    }

    .carousel-swiper3-block {
      animation: fadenum 3s;
    }

    .carousel-swiper3-img {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: inherit;
    }
  }

  .carousel-swiper4 {
    width: 100%;
    height: 100vh;
    margin-top: -1px;
    position: relative;

    .carousel-swiper4-block {
      position: relative;
      z-index: 6;
      width: 100%;
      top: 110px;

      .carousel-swiper4-block-first {
        .block-first-div {
          width: 350px;
          height: 77px;
          position: relative;
          margin: 0 auto;

          .block-first-div-title {
            font-size: 55px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          .block-first-div-text {
            font-size: 35px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            position: absolute;
            top: 35px;
            opacity: 0.3;
            display: flex;
            justify-content: center;
            letter-spacing: 0.2px;
          }
        }
      }

      .carousel-swiper4-block-second {
        width: 1300px;
        margin: 0 auto;
        margin-top: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .block-second-list {
          width: 310px;
          height: 272px;
          background: #ffffff;
          box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          margin-right: 19px;
          margin-bottom: 24px;

          .block-second-list-play {
            width: 310px;
            height: 200px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .block-second-list-play-back {
              position: absolute;
              display: flex;
              width: 100%;
              height: 100%;
            }

            .block-second-list-play-img {
              width: 82px;
              height: 82px;
              position: relative;
              cursor: pointer;
            }
          }

          .block-second-list-div {
            padding: 11px 0 11px 20px;
            box-sizing: border-box;
            text-align: left;
            .block-second-list-div-title {
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .block-second-list-div-text {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #b4b4b4;
              margin-top: 4px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .block-second-list:nth-child(4n + 4) {
          margin-right: 0;
        }
      }
    }

    .carousel-swiper4-img {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: inherit;
    }
  }

  .carousel-swiper5 {
    width: 100%;
    height: 100vh;
    margin-top: -1px;
    position: relative;

    .carousel-swiper5-block {
      position: relative;
      z-index: 6;
      width: 100%;
      top: 110px;

      .carousel-swiper5-block-first {
        .block-first-div {
          position: relative;
          margin: 0 auto;

          .block-first-div-title {
            font-size: 55px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }

          .block-first-div-text {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-top: 20px;

            opacity: 0.7;
          }
        }
      }

      .carousel-swiper5-block-second {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .block-second-list {
          margin-right: 101px;
          width: 352px;
          .block-second-list-img {
            width: 212px;
            height: 212px;
          }

          .block-second-list-title {
            font-size: 26px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            margin: 24px 0 12px;
          }
          .block-second-list-text {
            font-size: 15px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
            text-align: left;
          }
        }

        .block-second-list:last-child {
          margin-right: 0;
        }
      }

      .carousel-swiper5-block-thirdly {
        width: 232px;
        height: 50px;
        border-radius: 6px;
        border: 1px solid #ffffff;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-top: 104px;
      }
    }

    .carousel-swiper5-img {
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: inherit;
    }
  }

  .carousel-swiper6 {
    width: 100%;
    height: 100vh;
    margin-top: -1px;
    position: relative;
    padding-top: 2%;
    box-sizing: border-box;

    .carousel-swiper6-block {
      display: flex;
      justify-content: center;
      align-items: center;

      .carousel-swiper6-block-left,
      .carousel-swiper6-block-right {
        width: 482px;
        height: 358px;
        border: 1px solid #bdbdbd;
        margin-right: 51px;
        display: flex;
        align-items: center;
        justify-content: center;

        .block-left-div {
          .block-left-div-img {
            width: 80px;
            height: 80px;
            margin: 0 auto;
          }
          .block-left-div-phone {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2481d7;
            margin: 24px 0 19px;
          }
          .block-left-div-num {
            font-size: 30px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #333333;
          }

          .block-left-div-num1 {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-top: 10px;
            letter-spacing: 1px;
          }

          .block-left-div-dizhi {
            width: 72px;
            height: 100px;
          }
        }
      }

      .carousel-swiper6-block-right {
        width: 766px;
        height: 358px;
        border: 1px solid #bdbdbd;
        margin-right: 0px;
      }
    }

    .carousel-swiper6-tab {
      position: absolute;
      width: 100%;
      display: flex;
      bottom: 0;
      left: 0;
    }
  }
}

.animation {
  animation: fadenum 2s;
}

@keyframes fadenum {
  0% {
    transform: translateY(-251px);
  }
}
</style>
