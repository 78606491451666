<template>
    <div class="deal">
        <div class="deal-title">汇智启航科技用户服务协议</div>
        <div class="deal-text">生效日期：2024年7月 12日</div>
        <div class="deal-text">特别提示：</div>
        <div class="deal-text1">欢迎您签署《汇智启航科技用户服务协议》（以下简称“本协议”）并使用汇智启航科技网络服务。</div>
        <div class="deal-text1">您在申请注册流程中点击同意本协议前，应当阅读并遵守本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，对此内容应重点阅读。</div>
        <div class="deal-text1">
            您按照注册流程填写信息、阅读并点击有关“我已阅读并同意”按钮的，即视为您已充分阅读、理解本协议，并自愿接受本协议的约束。若不同意本协议中的任何内容，则应当停止注册或使用本平台服务。如您是未成年人，您还应当要求您的监护人仔细阅读本协议，并取得他们的同意。
        </div>
        <div class="deal-text1">本”特别提示”是本协议正文的组成部分。</div>
        <div class="deal-text2">1. 定义</div>
        <div class="deal-text1">1.1汇智启航科技应用平台：是指深圳市汇智启航科技有限责任公司经营的网站（以下简称“本应用平台”）。</div>
        <div class="deal-text1">2. 服务须知</div>
        <div class="deal-text1">2.1本应用平台仅运用自身开发的操作系统通过互联网络为您提供购买商品等相关的网络服务，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他接入互联网或移动网有关的装置)及所需的费用(如接入互联网而支付的电话费及上网费、使用移动网而支付的手机费)均应由您自行负担。</div>
        <div class="deal-text1">2.2为使您更好地使用平台的各项服务，保障您的账户安全，本应用平台可要求您按相关法律法规规定完成实名认证。</div>
        <div class="deal-text1">2.3为提高您的使用感受和满意度，您同意本应用平台将基于您的操作行为对您的数据进行调查研究和分析，从而进一步优化服务。</div>
        <div class="deal-text1">2.4本应用平台不保证为方便您使用而设置的外部链接的及时性和完整性，同时，对于外部链接指向的不由本应用平台实际控制的任何网页内容，本应用平台不承担任何责任。</div>
        <div class="deal-text1">2.5您知晓网络服务固有的风险并自愿承担该风险可能导致的不利后果。</div>
        <div class="deal-text1">2.6您上传至本应用平台的内容仅代表您的个人立场和观点，与本应用平台无关，您应当对上传的内容承担相应法律责任。</div>
        <div class="deal-text1">2.7若您在本应用平台从事非法行为，或发表不正当言论、涉黄信息等，本应用平台的记录有可能作为您违反法律的证据。</div>
        <div class="deal-text2">3. 账户注册及使用</div>
        <div class="deal-text1">3.1您在本应用平台注册时，只能使用汉字、英文字母、数字、下划线及它们的组合，若您使用空格、各种符号或特殊字符，或超过16个字符(8个汉字)申请注册的，本应用平台将不予注册。</div>
        <div class="deal-text1">3.2若您使用第三方合作网站登录的，只能使用汉字、英文字母、数字、下划线及它们的组合，若您使用空格、各种符号或特殊字符，或超过14个字符(7个汉字)申请注册的，本应用平台将只截取前14个字符（7个汉字）予以显示用户帐号（若您的帐号与应用现有用户帐号重名，系统将随机添加一个字符以示区别），否则将不予注册。</div>
        <div class="deal-text1">3.3如您被发现帐号中含有以下不雅文字或不恰当名称而违反法律法规规定或社会公序良俗的，本应用平台有权收回该用户的账号名称。</div>
        <div class="deal-text1">（1）以党和国家领导人或其他社会名人的真实姓名、字号、艺名、笔名注册；</div>
        <div class="deal-text1">（2）以国家机构或其他机构的名称注册；</div>
        <div class="deal-text1">（3）含有不文明、不健康名称，或包含歧视、侮辱、猥亵类词语；</div>
        <div class="deal-text1">（4）易产生歧义、引起他人误解或其它不符合法律规定的。</div>
        <div class="deal-text1">3.4您有义务确保自身的帐号和密码安全。若您因利用该帐号和密码从事违法活动而导致损失或损害的，您将自行承担全部责任，本应用平台不承担任何责任。如您发现帐号和密码存在安全问题的，应立即修改帐号密码并妥善保管。如有必要，可反馈本应用平台管理人员。若因黑客行为或您的保管疏忽导致帐号被非法使用的，本应用平台不承担任何责任。</div>
        <div class="deal-text1">3.5如您在使用本网络服务时违反以下任意约定的，本应用平台均有权要求您改正或直接采取一切必要的措施(包括但不限于删除您上传的内容、暂停或终止您使用网络服务的权利)以减轻您的不当行为造成的不利影响。</div>
        <div class="deal-text1">(1)不得以任何非法目的而使用网络服务系统；</div>
        <div class="deal-text1">(2)遵守所有与网络服务有关的网络协议、规定和程序；</div>
        <div class="deal-text1">(3)不得利用本应用平台的服务进行任何可能对互联网的正常运转造成不利影响的行为；</div>
        <div class="deal-text1">(4)不得利用本应用服务进行任何不利于本应用平台的行为。</div>
        <div class="deal-text1">3.6您注册时系统默认您同意加入已有的店主商家社群，后续使用过程中，您可以自主操作退出该社群。</div>
        <div class="deal-text2">4. 平台服务及规范</div>
        <div class="deal-text1">4.1当您在本应用平台购买商品时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。</div>
        <div class="deal-text1">4.2您的购买行为应当基于真实的消费需求，不得存在对商品实施恶意购买、恶意维权等扰乱本应用平台正常交易秩序的行为。基于维护本应用平台交易秩序及交易安全的需要，如被发现上述情形平台可主动执行关闭相关交易订单等操作。</div>
        <div class="deal-text1">4.3由于市场变化及各种以合理商业努力仍难以控制因素的影响，本应用平台可能无法避免您提交的订单信息中的商品出现缺货、价格标示错误等情况；如您下单所购买的商品出现以上情况，您有权取消订单，本应用平台亦有权依法、依约取消订单，若您已经付款，则为您办理退款手续。</div>
        <div class="deal-text1">4.4您在本应用平台购买的商品将按照本应用平台上您所指定的送货地址进行配送。您应当清楚、准确地填写您的送货地址、联系人及联系方式等配送信息，您知悉并确认，您所购买的商品应仅由您填写的联系人接受身份查验后接收商品，因您变更联系人或相关配送信息而造成的损失由您自行承担。</div>
        <div class="deal-text1">4.5本应用平台的具体内容由平台经营者根据实际情况提供。</div>
        <div class="deal-text2">5. 个人信息保护及授权</div>
        <div class="deal-text1">5.1您在使用本应用平台提供的服务时，您同意按照在本应用平台上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。</div>
        <div class="deal-text1">5.2您承诺自己所发表或者上传本应用平台的所有信息，包括但不限于文字、图片、音乐、电影、表演和录音录像制品、电脑程序等，均享有完整的知识产权，或者已经得到相关权利人的合法授权。如您违反本条约定而造成的一切损失，皆由您承担。</div>
        <div class="deal-text2">6. 违约责任</div>
        <div class="deal-text1">6.1若本应用平台发现或收到第三方举报投诉您违反本协议约定或存在其他任何恶意行为的，您同意本应用平台可以不经通知而自行判断决定删除、屏蔽涉嫌侵权信息，除非您提交书面证据材料排除侵权的可能性，否则本应用平台将不会自动恢复上述删除、屏蔽的信息。</div>
        <div class="deal-text1">6.2您知晓并认可，因您违反本协议的约定，导致第三方主张的任何索赔、要求或损失，您应当独立承担责任；若本应用平台因此遭受损失的，您也应当一并赔偿，赔偿范围包括但不限于和解金、赔偿金、行政处罚金、诉讼费、律师费、差旅费等一切费用。</div>
        <div class="deal-text2">7. 所有权及知识产权</div>
        <div class="deal-text1">7.1 您一旦同意本协议内容，视为您提供、上传、创作、发布在本应用平台的文字、评论、图片、照片、视频、音频等任何形式的作品，免费授予本应用平台及其关联公司至保护期终止为止、不可撤销的、排他的著作权许可使用权、肖像权、用户ID名称等相关的全部合法权利权益。您同意本应用平台及其关联公司有权存储、使用、复制、修订、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播您以上信息内容，有权改编制作派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。</div>
        <div class="deal-text1">7.2 针对5.1的所有权利，您认可本应用平台有权再许可给其他第三方使用。</div>
        <div class="deal-text1">7.3 您应确保在本应用平台上发表的5.1列明的各类信息内容均不涉及侵犯第三方肖像、隐私、知识产权或其他合法权益。否则本应用平台有权随时采取包括但不限于删除、断开链接等措施。</div>
        <div class="deal-text1">7.4 除法律强制性规定外，未经本应用平台书面同意,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本网站的信息内容，否则，本应用平台有权追究其法律责任。</div>
        <div class="deal-text1">7.5 本应用平台所刊登的资料信息（如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是本应用平台或其内容提供者的财产，受我国版权法的保护。本应用平台上所有内容的汇编是本应用平台的排他财产，受我国版权法的保护。本网站上所有软件都是本应用平台或其关联公司或其软件供应商的财产，受我国版权法的保护。以上内容，未经本应用平台或相关权利人的书面许可，任何单位或个人不得以任何形式使用或创造相关衍生作品。</div>
        <div class="deal-text1">7.6 任何网站、单位或者个人如认为本应用平台或者本应用平台提供的相关内容涉嫌侵犯其合法权益，应及时向本应用平台提交书面权利通知，并提供身份证明、权属证明及详细侵权证据证明。本应用平台在收到上述文件后，将会尽快切断相关内容以确保相关网站、单位或者个人的合法权益得到保障。</div>
        <div class="deal-text2">8. 协议修改</div>
        <div class="deal-text1">8.1基于国家法律法规变化及维护交易秩序、保护消费者权益的需要，本应用平台将不时修改本协议，变更后的协议将通过法定程序并以本协议第9条约定的方式通知您。</div>
        <div class="deal-text2">9. 通知与送达</div>
        <div class="deal-text1">9.1您在注册成为本应用平台用户时，应提供真实有效的联系方式（包括电子邮件地址、联系电话、联系地址等）。若您的联系方式发生变更的，您应当及时更新有关信息，并保持可被联系的状态，否则您自行承担由此可能产生的法律后果。本应用平台将向您填写的上述联系方式信息送达各类通知，而此类通知的内容可能对您的权利义务产生重大的影响，请您务必及时关注。</div>
        <div class="deal-text1">9.2本应用平台通过上述联系方式向您发出通知，其中以电子方式发出的书面通知，包括但不限于在本应用平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</div>
        <div class="deal-text1">9.3对于您在本应用平台上因交易活动引起的任何纠纷，您同意司法机关可以通过手机短信、电子邮件、系统消息等电子通讯方式或邮寄方式向您送达法律文书。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在本应用平台注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</div>
        <div class="deal-text2">10. 法律管辖使用及其他</div>
        <div class="deal-text1">10.1本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决。协商不成时，应向被告所在地人民法院提起诉讼。</div>
        <div class="deal-text1">10.2如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条并不影响任何其余条款的有效性和可执行性。</div>
        <div class="deal-text1">10.3本协议未明示授权的其他权利仍由本应用平台保留，您在行使这些权利时须另外取得本应用平台的书面许可。本应用平台如果未行使前述任何权利，并不构成对该权利的放弃。</div>
    </div>
</template>


<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang="less" scoped>
.deal {
  text-align: left;
  padding: 20px 10px;
  box-sizing: border-box;
  .deal-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .deal-text {
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px;
  }

  .deal-text1,
  .deal-text2, .deal-text3 {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: 400;
    word-break: break-all;
  }

  .deal-text2 {
    font-size: 18px;
    font-weight: bold;
  }

  .deal-text3 {
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
  }
}

</style>